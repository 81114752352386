





















import Vue, { PropType } from "vue";
import {
  extract_skill_group_styling,
  get_skills_group
} from "@/utils/skills_group";
import { SkillsWithGroups } from "@/interfaces/shared/skills_with_groups";
import { TranslateResult } from "vue-i18n";
import SkillsGroup from "@/components/shared/SkillsGroup.vue";
import SkillsChips from "@/components/shared/SkillsChips.vue";
import { Candidates } from "@/interfaces/data_objects/candidates";

export default Vue.extend({
  name: "SkillsWithGroups",
  components: { SkillsChips, SkillsGroup },
  data(): SkillsWithGroups {
    return {
      active_skill_group: ""
    };
  },
  props: {
    title: {
      type: String as PropType<TranslateResult>,
      required: true
    },
    assessment: {
      type: Object as PropType<Candidates.Assessment>
    },
    skills: {
      required: true
    },
    allow_add: {
      type: Boolean,
      default: false
    },
    card_base: {
      type: Boolean,
      default: true
    },
    removeable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    extract_skill_group_styling,
    get_skills_group,
    update_skill_group(skill_group: string) {
      if (skill_group === this.active_skill_group) this.active_skill_group = "";
      else this.active_skill_group = skill_group;
      console.log(this.active_skill_group);
    },
    add_event() {
      // console.log("ADD skill PRESSED");
    }
  }
});
