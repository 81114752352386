

































































import Vue, { PropType } from "vue";
import { get_random_color } from "@/utils/colors";
import { Jobs } from "@/interfaces/data_objects/jobs";
import { get_job_icon } from "@/utils/jobs_icons";

export default Vue.extend({
  name: "ViewStandardisedJobCard",
  data() {
    return {
      font_color: "",
      background_color: "",
      color_code: ""
    };
  },
  props: {
    job: {
      type: Object as PropType<Jobs>
    },
    show_industry: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    const color = get_random_color(); // Get random color
    this.font_color = `color: ${color}`;
    this.background_color = `background: ${color}`;
    this.color_code = color;
  },
  methods: {
    get_job_icon,
    transform_job_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children)
        node.setAttribute("fill", this.color_code);
      return icon;
    }
  }
});
