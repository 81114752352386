
















import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "GoBackHeader",
  props: {
    title: {
      type: String as PropType<TranslateResult>,
      required: true
    }
  }
});
