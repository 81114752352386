import { clean_string } from "@/utils/global";

const COLOR_POSSIBILITIES: string[] = [
  "#1C53F4",
  "#FF4066",
  "#FF8473",
  "#3CE17E",
  "#FFB815",
  "#EE49FD"
];

/**
 * Return random color from COLOR_POSSIBILITIES
 * @return string => hex color code
 */
export function get_random_color(): string {
  const length = COLOR_POSSIBILITIES.length;
  const number = Math.floor(Math.random() * length + 1);
  return COLOR_POSSIBILITIES[number - 1];
}

/**
 * Return random color based on name
 * @param name
 */
export function get_random_color_hash(name: string): number | string {
  let hash = 0;
  if (name.length === 0) return hash.toString();
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 255;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
}

export function get_color_by_name(name: string): string {
  const input = clean_string(name);
  switch (input) {
    case "page-bg":
      return "#FAFCFF";
    case "primary":
      return "#1C53F4";
    case "primary-dark":
      return "#2C32A9";
    case "primary-light":
      return "#33BFFF";
    case "danger":
      return "#FF4066";
    case "danger-light":
      return "#FF8473";
    case "success":
      return "#3CE17E";
    case "info":
      return "#2DFFF5";
    case "warning":
      return "#FFB815";
    case "pink":
      return "#EE49FD";
    case "white":
      return "#ffffff";
    case "dark":
      return "#000000";
    case "dark-darker":
      return "#0F1322";
    case "grey":
      return "#858D98";
    case "light-bg":
      return "#F1F5FE";
    case "border":
      return "rgba(28, 83, 244, 0.06)";
    case "light-border":
      return "rgba(133, 141, 152, 0.2)";
    case "text-dark":
      return "#4B4E56";
    case "danger-bg":
      return "rgba(227, 45, 107, 0.1)";
    case "danger-light-bg":
      return "rgba(255, 247, 245, 1)";
    case "primary-light-bg":
      return "rgba(246, 249, 255, 1)";
    case "success-bg":
      return "rgba(244, 252, 245, 1)";
    default:
      return "#1C53f4";
  }
}

/**
 * Function to get color based on matching score
 */
export function get_color_by_matching_score(score: number): string {
  if (score < 0) return get_color_by_name("grey");
  if (score >= 0 && score <= 0.3) return get_color_by_name("danger");
  else if (score >= 0.3 && score <= 0.5)
    return get_color_by_name("danger-light");
  else if (score >= 0.5 && score <= 0.8) return get_color_by_name("warning");
  else if (score >= 0.8) return get_color_by_name("success");
  else return get_color_by_name("grey");
}
