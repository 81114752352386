
















































import Vue from "vue";
import {
  extract_skill_group_styling,
  get_skills_group,
  SkillsGroup
} from "@/utils/skills_group";
import { mapGetters } from "vuex";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";

export default Vue.extend({
  name: "SkillsGroup",
  props: {
    active_skill_group: {
      type: String,
      required: true,
      default: ""
    }
  },
  data() {
    return {
      skill_groups: [] as SkillsGroup[]
    };
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    }
  },
  created() {
    this.skill_groups = get_skills_group();
  },
  watch: {
    get_site_direction() {
      this.skill_groups = get_skills_group();
    }
  },
  methods: {
    get_skills_group,
    extract_skill_group_styling
  }
});
